import { mapState, mapGetters } from 'vuex';
// import { getItem } from '../../../../api/api-methods';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

import KnTable from '@/modules/shared/components/KnTable.vue';
import { fetchGroups } from '../../helpers/reportCardOptions';
import { getFullName } from '../../../shared/helpers/dataUtils';
import { canChange, canDelete } from '../../../shared/helpers/permissionsUtils';
import {
  deleteGroup,
  reactivateGroup,
} from '../../helpers/subjectsTeacherOptions';

export default {
  name: 'SpecialGroupsIndex',
  components: { KnTable },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      groups: [],
      headers: [
        { text: '', value: 'foto' },
        { text: 'Nombre', value: 'nombre_grupo', class: 'purple--text' },
        {
          text: 'No. de alumnos',
          value: 'n_alumnos',
          class: 'purple--text',
        },
        // {
        //   text: 'Ciclo escolar',
        //   value: 'ciclo',
        //   class: 'purple--text',
        // },
        {
          text: 'Nivel educativo',
          value: 'nivel_educativo.nombre',
          class: 'purple--text',
        },
        {
          text: 'Materia',
          value: 'materia',
          class: 'purple--text',
        },
        {
          text: 'Profesor',
          value: 'profesor',
          class: 'purple--text',
        },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      inactiveItems: [],
      // search: null,
      showInactive: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search']),
    ...mapGetters(['isTeacherEmploye', 'employeeId']),
    buttomActionText() {
      return this.showInactive
        ? 'Ver grupos especiales activos'
        : 'Ver grupos especiales inactivos';
    },
    tableTitle() {
      return this.showInactive
        ? 'Grupos especiales inactivos'
        : 'Grupos especiales';
    },
  },
  watch: {
    search: {
      async handler() {
        await this.getPaginatedGroups(1);
      },
    },
    showInactive: {
      async handler() {
        await this.getPaginatedGroups(1);
      },
    },
  },
  async created() {
    await this.getPaginatedGroups(1);
  },
  methods: {
    async getPaginatedGroups(page = 1) {
      try {
        if (!this.loading) {
          this.setPaginationPage(page);
          this.loading = true;
          this.groups = [];
          this.items = [];
          const { ok, data, message, count } = await fetchGroups({
            institutionId: this.institutionId,
            groupName: this.search,
            systemStatus: !this.showInactive,
            isSpecial: true,
            teacherId: this.isTeacherEmploye ? this.employeeId : null,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });
          this.setPaginationCount(count);
          if (ok) {
            const items = data;
            for (const group of items) {
              const subjectName = group.materia_profesor.length
                ? this.getSubjectName(group.materia_profesor[0])
                : 'No especificado';
              const teacherName = group.materia_profesor.length
                ? this.getTeacherName(group.materia_profesor[0])
                : 'No especificado';
              const item = {
                ...group,
                foto: group.imagen_grupo ? group.imagen_grupo.imagen : null,
                n_alumnos: group.alumnos.length,
                materia: subjectName,
                profesor: teacherName,
              };
              this.groups.push(item);
            }
          } else {
            console.log('No se pudieron obtener los grupos', message);
          }
        }
      } catch (error) {
        console.error('Error al obtener grupos. ', error);
      } finally {
        this.loading = false;
      }
    },
    getSubjectName(subjectTeacher) {
      return subjectTeacher && subjectTeacher.materia
        ? subjectTeacher.materia.nombre
        : '';
    },
    getTeacherName(subjectTeacher) {
      let fullname = '';
      if (
        subjectTeacher &&
        subjectTeacher.profesor &&
        subjectTeacher.profesor.datos_personales
      ) {
        fullname = getFullName(subjectTeacher.profesor.datos_personales);
      }
      return fullname;
    },
    async action2(groupId) {
      if (this.showInactive) {
        await this.enableGroup(groupId);
      } else {
        await this.disableGroup(groupId);
      }
    },
    async disableGroup(groupId) {
      try {
        if (canDelete('grupo')) {
          const { ok, message } = await deleteGroup(groupId);
          if (ok) {
            await this.getPaginatedGroups(1);
          } else {
            console.log('No se pudo desactivar el grupo. ' + message);
          }
        }
      } catch (error) {
        console.error('Error al intentar desactivar grupo. ', error);
      }
    },
    async enableGroup(groupId) {
      try {
        if (canChange('grupo')) {
          const { ok } = await reactivateGroup(groupId);
          if (ok) {
            // this.alert = 'Grupo activado exitosamente';
            this.getPaginatedGroups(1);
          } else {
            console.log('No se pudo activar el grupo');
          }
        }
      } catch (error) {
        console.error('Error al intentar activar grupo. ', error);
      }
    },
  },
};
